import {
    initPromoArticlelist
} from './init';
import {Logger} from "./logger";

window.o_global.eventQBus.on("ft3.promo-articlelist.init", () => {
    Logger.group("[FT3 promo articlelist]");
    Logger.count("Articlelist Invocation");
    initPromoArticlelist();
    Logger.groupEnd();
});

window.o_global.eventLoader.onAllPreloadScriptsLoaded(95, () => { window.o_global.eventQBus.emit("ft3.promo-articlelist.init")})