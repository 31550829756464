import TrackingService from "./tracking_service";
import VariationData from "./variation_data";

export default class PromoArticlelist {

    constructor() {
        this.trackingService = new TrackingService();
    }

    addClickHandlers(container) {
        container.querySelectorAll('.promo_articlelist--article-link').forEach(link => {
            link.addEventListener('click', function () {
                window.o_promo_articlelist.trackClick(link);
            })
        })
        container.querySelectorAll('.promo_articlelist--article-wishlistheart').forEach(heartBtn => {
            heartBtn.addEventListener('click', function () {
                window.o_promo_articlelist.toggleWishlistHeart(heartBtn);
            })
        })
    }

    trackView(container) {
        const items = container.querySelectorAll('.promo_articlelist--article');
        const variations = [].map.call(items, item => this._createVariationData(item, undefined));
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this._getFeatureIndex(container);
        this.trackingService.sendViewTrackingEvent(featureOrder, featureIndex, variations.length, variations);
    }
    registerScrollTrackObserver(container) {
        const featureIndex = this._getFeatureIndex(container);
        const items = document.querySelectorAll('.promo_articlelist--article');
        /*                                     */
        const observer = new IntersectionObserver((entries) => {
            const intersectingEntries = entries.filter(entry => entry.isIntersecting);
            if (intersectingEntries.length > 0) {
                const positionsToTrack = intersectingEntries.map(entry => this._getArticlePosition(entry.target));
                this.trackingService.sendScrollTrackingEvent(featureIndex, positionsToTrack);
            }
            intersectingEntries.forEach(entry => observer.unobserve(entry.target));
        });
        items.forEach(item => observer.observe(item));
    }

    toggleWishlistHeart(heartBtn) {
        const articleTile = this._findParentArticle(heartBtn);
        const heartEnabled = heartBtn.className.indexOf('p_selectBtn50--selected') >= 0;
        this.toggleHeartEnabled(heartBtn, !heartEnabled, false);
        if (heartEnabled) {
            const variationId = articleTile.getAttribute('data-variation-id');
            window.o_global.eventQBus.emit('ft1.wishlist.remove', {
                'variationIds': [variationId],
                'deletedFrom': 'PromoArticleList'
            });
        } else {
            const variationId = articleTile.getAttribute('data-variation-id');
            const referencePrice = parseInt(articleTile.getAttribute('data-reference-price'));
            const productTitle = articleTile.querySelector('a').getAttribute('title') || 'Unknown';
            window.o_global.eventQBus.emit('ft1.wishlist.add',
                {
                    'variationId': variationId,
                    'addedFrom': 'PromoArticleList',
                    'qualified': false,
                    'name': productTitle,
                    'productTitle': productTitle,
                    'referencePrice': referencePrice,
                    'actionType': 'heart'
                });
        }

    }

    toggleHeartEnabled(heartBtn, enabled, initialSetup) {
        const defaultClasses = 'promo_articlelist--article-wishlistheart p_selectBtn50';
        if (!initialSetup) {
            if (enabled) {
                heartBtn.className = defaultClasses + ' p_selectBtn50--animated p_selectBtn50--selected';
            } else {
                heartBtn.className = defaultClasses + ' p_selectBtn50--animated-reverse';
            }
        } else {
            if (enabled) {
                heartBtn.className = defaultClasses + ' p_selectBtn50--selected';
            }
        }
    }

    trackClick(item) {
        const container = this._findParentContainer(item);
        const articleContainer = this._findParentArticle(item);
        const featureOrder = this._getFeatureOrder(container);
        const featureIndex = this._getFeatureIndex(container);
        const items = container.querySelectorAll('.promo_articlelist--article');
        const filledSlots = items.length;

        let isOnWishlist;
        if (container.getAttribute('data-wishlist-heart-enabled') === "true") {
            isOnWishlist = articleContainer.querySelectorAll(".p_selectBtn50--selected").length > 0;
        } else {
            isOnWishlist = undefined;
        }
        const position = this._getArticlePosition(articleContainer);
        let variation = this._createVariationData(articleContainer, isOnWishlist);
        this.trackingService.sendClickTrackingEvent(featureOrder, featureIndex, filledSlots, position, variation);
    }

    _createVariationData(article, isOnWishlist) {
        return new VariationData(
            article.getAttribute('data-variation-id'),
            isOnWishlist,
            article.querySelector(".promo_articlelist--description-name").innerHTML);
    }

    _findParentContainer(elem) {
        while (!elem.classList.contains("promo_articlelist--container") && (elem = elem.parentElement)) ;
        return elem;
    }

    _findParentArticle(elem) {
        while (!elem.classList.contains("promo_articlelist--article") && (elem = elem.parentElement)) ;
        return elem;
    }

    _getFeatureOrder(container) {
        return parseInt(container.parentElement.getAttribute('data-feature-order'), 0);
    }

    _getFeatureIndex(container) {
        return parseInt(container.getAttribute('data-feature-index'), 0);
    }

    _getArticlePosition(articleContainer) {
        return parseInt(articleContainer.getAttribute('data-article-position'));
    }
}
