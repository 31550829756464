export default class VariationData {

    constructor(variationId, isOnWishlist, articleName) {
        this._variationId = variationId;
        this._isOnWishlist = isOnWishlist;
        this._articleName = articleName;
    }

    get variationId() {
        return this._variationId;
    }

    get isOnWishlist() {
        return this._isOnWishlist;
    }

    get articleName() {
        return this._articleName;
    }
}