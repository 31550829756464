import PromoArticlelist from "./articlelist";

window.o_promo_articlelist = new PromoArticlelist();

export function initPromoArticlelist() {
    const containers = document.getElementsByClassName('promo_articlelist--container');
    [].forEach.call(containers, container => {
        if (container.getAttribute("data-tracked") !== "true") {
            container.setAttribute("data-tracked", "true");
            window.o_promo_articlelist.trackView(container);
            window.o_promo_articlelist.addClickHandlers(container);
            window.o_promo_articlelist.registerScrollTrackObserver(container);
        }
    });
}
